<template>
    <div class="contons">
        <div class="container" id="container">
            <el-drawer
                :with-header="false"
                title="图片展示"
                :visible.sync="table"
                direction="ttb"
                size="50%"
                close-on-press-escape="true"
            >
                <el-tabs type="border-card" :stretch="true" style="width: 100%; height: 100%">
                    <el-tab-pane label="红外">
                        <el-row :gutter="6">
                            <el-col
                                :span="6"
                                v-for="(item, index) in list.infraredUrl"
                                :key="index"
                            >
                                <el-image
                                     style="width: 100%; height: 100%"
                                    :src="item"
                                    :preview-src-list="list.infraredUrl"
                                    fit="cover"
                                    @click.stop="handleClickItem(item)"
                                ></el-image>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                    <el-tab-pane label="可见光">
                        <el-row :gutter="6">
                            <el-col :span="6" v-for="(item, index) in list.wideUrl" :key="index">
                                <el-image
                                    style="width: 100%; height: 100%"
                                    :src="item"
                                    fit="cover"
                                    :preview-src-list="list.wideUrl"
                                ></el-image>
                            </el-col>
                        </el-row>
                    </el-tab-pane>
                </el-tabs>
            </el-drawer>
            <a class="container-button" :href="url">
                <el-button type="primary" round>高德app</el-button>
            </a>
            <el-button class="container-img" type="primary" round @click="table = true">
                查看图片
            </el-button>
        </div>
    </div>
</template>

<script>
import AMapLoader from '@amap/amap-jsapi-loader';
// import coordtransform from 'coordtransform';
window._AMapSecurityConfig = {
    // 设置安全密钥-
    securityJsCode: 'e604b1d613ef230d980acc19b030b0b7',
};

export default {
    components: {},
    data() {
        return {
            loading: true,
            table: false,
            marker: null,
            position: null,
            map: null,
            url: null,
            list: {},
        };
    },
    computed: {},
    methods: {
        //加载区域
        initMap(path) {
            AMapLoader.load({
                key: 'c678f3ad22723c0d68aba1ce16ff5d4a', //此处填入我们注册账号后获取的Key
                version: '2.0', //指定要加载的 JSAPI 的版本，缺省时默认为 1.4.15
                plugins: [
                    'AMap.MouseTool',
                    'AMap.Rectangle',
                    'AMap.Polyline',
                    'Amap.Polygon',
                    'AMap.LngLat',
                    'AMap.Marker',
                    'AMap.Pixel',
                    'AMap.PlaceSearch',
                    'AMap.AutoComplete',
                ], //需要使用的的插件列表，如比例尺'AMap.Scale'等
            })
                .then(AMap => {
                    // this.mapModule = AMap; // 将它赋值给定义的变量，以便后续其他方法需要使用
                    this.marker,
                        (this.map = new AMap.Map('container', {
                            // resizeEnable: true,
                            // layers: [new AMap.TileLayer()],
                            //  mapStyle: 'amap://styles/blue',
                            //设置地图容器id
                            viewMode: '2D', //是否为3D地图模式
                            // pitch:45,
                            // layers: [new AMap.TileLayer.Traffic()],
                            zoom: 19, //初始化地图级别
                            center: path, //初始化地图中心点位置
                        }));
                    this.setMapMarker();
                })
                .catch(e => {
                    console.log('map', e);
                });
        },
        imgPoints() {
            this.$axios
                .get('/api/infrared/getNewInfo', {
                    headers: {
                        Appid: 'admin',
                        Token: 'aibit@605',
                    },
                })
                .then(reponse => {
                    if (reponse.data.code == 0) {
                      
                        this.list = reponse.data.data;
                        console.log(this.list);
                        this.url = 'https://uri.amap.com/marker?position=' + this.list.position;
                        this.position = [
                            this.list.position.split(',')[0],
                            this.list.position.split(',')[1],
                        ];
                        this.initMap(this.position);
                    } else {
                        this.$message.error('获取地址失败请检查您的网络！');
                    }
                });
        },
        //绘制小无人机
        setMapMarker() {
            this.marker = new AMap.Marker({
                position: this.position,
                // offset: new AMap.Pixel(-26, -13),
                offset: new AMap.Pixel(-13, -13),
                autoRotation: true,
                angle: 0,
            });
            this.map.add(this.marker);
            // this.marker.on('touchstart', e => {
            this.marker.on('touchstart', e => {
                this.table = true;
                // this.$emit('transmit', true)
            });
        },
        handleClickItem(item) {
            this.$nextTick(() => {
                let domImageView = document.querySelector('.el-image-viewer__mask'); // 获取遮罩层dom
                let closeEle = document.querySelector('.el-image-viewer__close'); // 获取遮罩层关闭按钮dom
                if (!domImageView || !closeEle) {
                    return;
                }
                domImageView.addEventListener('click', () => {
                    console.log('点击了遮罩层');
                    
                    
                });
                closeEle.addEventListener('click', () => {
                    console.log('点击了关闭按钮');
                    // 页面刷新
                });
            });
        },
    },
    created() {
        this.imgPoints();
        this.$nextTick(() => {
            
        });
    },
    mounted() {},
    beforeDestroy() {
        this.map.destroy();
    },
};
</script>

<style scoped>
:deep() .amap-logo {
    display: none !important;
}
:deep() .amap-copyright {
    display: none !important;
}
.contons {
    width: 100%;
    height: 100%;
    /* overflow: hidden; */
    position: absolute;
}
.container {
    width: 100%;
    height: 100%;
    position: relative;
    overflow: hidden;
    /* z-index: 0; */
}
.container-button {
    position: absolute;
    z-index: 100;
    display: block;
    right: 20px;
    bottom: 20px;
}
.container-img {
    position: absolute;
    z-index: 100;
    display: block;
    right: 20px;
    bottom: 70px;
}
</style>
